// Override default variables before the import
@import './_variables.scss';
@import './typography.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::placeholder {
    color: $gray-color;
    opacity: 1;
}

.App {
    .btn-light {
        border-radius: $border-radius-btn-large;
        // font-size: 17px;
    }

    .submit-loading {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 9999;
        background-color: rgba($color: #fff, $alpha: 0.8);
    }

    #home {
        max-width: 100vw;
        height: 100vh;
        background: linear-gradient(0deg, rgba(48, 48, 48, 0.5), rgba(50, 50, 50, 0.5), rgba(48, 48, 48, 0.5)), url('../assets/jumbotron-9.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .home-left {
            height: 100vh;
            background-color: $opacity-white-6;

            &_title {
                color: $red-color;
                font-weight: 700;
                font-size: 50px;
            }

            &_subtitle {
                // font-size: 30px;
                color: #3f444a;

            }

            &_logo {
                max-width: 200px;
            }
        }

        .home-right {
            height: calc(100vh - 170px);

            .title {
                font-size: $font-size-h2;
                color: $darken-white-color;
            }

            .text {
                color: $darken-white-color;
                font-size: 18px;
            }
        }
    }

    #header {
        .navbar-toggler {
            background-color: $lightgray-color;

            &:hover {
                background-color: darken($lightgray-color, 10%);
            }
        }

        .nav-link {
            color: $lightgray-color;

            &:hover {
                color: darken($lightgray-color, 10%);
            }

            &.active {
                color: $white-color;
            }
        }

        .offcanvas-header {
            color: $lightgray-color;
            ;

            .btn-close {
                background-color: $lightgray-color;

                &:hover {
                    background-color: $darken-white-color;
                }
            }
        }

        .offcanvas.offcanvas-end.show,
        .offcanvas.offcanvas-end.showing {

            background-color: $lightblack-color;

        }

        nav {
            height: 60px;
        }
    }

    #services,
    #whychooseus,
    .slider,
    #statistics,
    #contact,
    #form {

        .title,
        .sub-title {
            color: #3f444a;
        }

        .text {
            color: #5c6873;
        }
    }


    #form {
        color: $lightblack-color;

        .btn-danger {
            background-color: $red-color;
            border: $red-color;

            &:hover {
                background-color: darken($red-color, 10%);
            }
        }

        .input-text {
            height: 50px;
            width: 100%;
            border-radius: $border-radius-large;
            border: 1px solid $darken-white-color;
            padding-left: 10px;
        }

        textarea {
            height: 120px;
            width: 100%;
            border-radius: $border-radius-large;
            border: 1px solid $darken-white-color;
            padding-left: 10px;
            padding-top: 10px;

        }

        .upload-label {
            cursor: pointer;
            width: 100%;

            border: 1px solid $darken-white-color;
            background-color: $white-color;
            border-radius: $border-radius-large;
            padding: 10px;
            color: $gray-color;
            font-family: inherit;

        }

        .hidden {
            display: none;
        }

        .uploaded-file {
            &_text {
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &_delete-icon {
                cursor: pointer;

                &:hover {
                    color: darken($gray-color, 10%);
                }
            }
        }
    }

    .slider {

        .slider-box {
            // max-width: 200px;
            background-color: $white-color;
            // position: relative;
            border: 1px solid #f0f0f0;
            border-radius: $border-radius-extreme;

            &_description {
                height: 100px;
                overflow-y: scroll !important;
            }
        }

    }

    #gallery {
        .container{
            max-width: 600px !important;

            .container-image {
                position: relative;

                .prev, .next {
                    position: absolute; 
                    transform: translate(0%, -50%); 
                    padding: $padding-base $padding-large !important;
                    border-radius: $border-radius-none !important;
                }
                .prev{
                    top: 50%; 
                    left: 0; 
                }
                .next{
                    top: 50%; 
                    right: 0; 
                }
            }
        }
    }

    #whatsapp {
        cursor: pointer;
        display: inline-block;
        z-index: 9999;
        position: fixed;
        bottom: 10px;
        right: 10px;
        border-radius: 50%;
        background-color: #25d366;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);

        &:hover {
            transition: 0.4s;
            background-color: darken(#25d366, 10%)
        }

        .whatsapp-circle {
            display: inline-block;
            color: $white-color;

            
        }
    }
    #arrow {
        cursor: pointer;
        display: inline-block;
        background-color: $white-color;
        z-index: 9999;
        position: fixed;
        bottom: 65px;
        right: 10px;
        border-radius: 50%;

        .arrow-circle {
            display: inline-block;
            color: #3f444a;

        }
        &:hover {
            transition: 0.4s;
            background-color: darken($white-color, 10%)
        }
    }


    #footer {
        background-color: #3f444a;
        font-size: 14px;
        color: $darken-white-color;
    }

}
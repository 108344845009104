$font-family-sans-serif:     'Montserrat', "Helvetica", Arial, sans-serif;
$sans-serif-family:          'Montserrat', 'Helvetica Neue',  Arial, sans-serif;
$font-family-roboto:         'Roboto', Arial, sans-serif;

$font-family-h1:             'Montserrat', Arial, sans-serif;


// color
$white-color:                #FFFFFF !default;
$darkwhite-color:            #fafafa !default;
$black-color:                #000000 !default;
$lightblack-color:           #333333 !default;
$lightgray-color:            #bebebe !default;
$gray-color:                 #777777 !default;
$darkgray-color:             #3f444a !default;
$red-color:                  #e7505a !default;


$white-bg:                   #FFFFFF !default;
$black-bg:                   #000000 !default;



$transparent:                 transparent !default;


//darken
$darken-white-color:          darken($white-color, 10%) !default;


//opacity
$opacity-gray-3:             rgba(222,222,222, .3) !default;
$opacity-gray-5:             rgba(222,222,222, .5) !default;
$opacity-gray-8:             rgba(222,222,222, .8) !default;

$opacity-white-6:            rgba(255, 255, 255, 0.6) !default;


// font size
$font-size-h1:                 2.5em        !default; // ~ 48px
$font-size-h2:                 2em          !default; // ~ 35px
$font-size-h3:                 1.714em      !default; // ~ 28px
$font-size-h4:                 1.57em       !default; // ~ 24px
$font-size-h5:                 1.20em       !default; // ~ 22px
$font-size-h6:                 1em          !default; // ~ 14px 


// border
$border-radius-none:            0px !default;
$border-radius-btn-small:       5px !default;
$border-radius-btn-base:       20px !default;
$border-radius-btn-large:      50px !default;


$border-radius-small:           3px !default;
$border-radius-base:            4px !default;
$border-radius-large:           6px !default;
$border-radius-x-large:         8px !default;
$border-radius-extreme:        12px !default;


// padding
$padding-small:                 1px !default;
$padding-base:                  3px !default;
$padding-large:                 6px !default;
$padding-x-large:              10px !default;
$padding-extreme:              20px !default;


// margin
$margin-small:               5px         !default;
$margin-base:               15px         !default;
$margin-large:              20px         !default;



// transition
$general-transition-time:        300ms       !default;
$slow-transition-time:           370ms       !default;
$fast-transition-time:           150ms       !default;

$transition-linear:         linear           !default;
$transition-bezier:         cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease:           ease 0s;



// Social icons color
$icon-font-size:               30px                  !default;
$icon-bg-hover-color:          #FFFFFF             !default;
$icon-light-color:             #FFFFFF             !default;
$icon-light-hover-color:       darken(#FFFFFF, 5%) !default;